import { useRecoilValueLoadable }                    from 'recoil';
import { useSymbolReloader, symbol as recoilSymbol } from './state/symbols';
import IconButton                                    from '@material-ui/core/IconButton';
import RefreshIcon                                    from '@material-ui/icons/Refresh';

function SymbolRowMessage ({ symbol, message, type = '', reload }) {
  return (
    <tr>
      <th scope="row">{symbol}</th>
      <td colSpan='4' className={`text-right ${type}`}>
        {message}
        {reload ? (
          <IconButton size='small' edge="end" aria-label="delete" onClick={() => reload(symbol)}>
            <RefreshIcon fontSize='small' />
          </IconButton>
        ) : ''}
      </td>
    </tr>
  );
}

export default function SymbolRow ({ symbol }) {
  const { state: status, contents: state } = useRecoilValueLoadable(recoilSymbol(symbol));
  const updateSymbol = useSymbolReloader(symbol);

  if (status === 'loading') {
    return <SymbolRowMessage
      symbol={symbol}
      type='muted'
      message={'... Loading'}
      reload={updateSymbol}
    />;
  } else if (status === 'hasError') {
    return <SymbolRowMessage
      symbol={symbol}
      type='danger'
      message={`Failed to fetch ${symbol}`}
      reload={updateSymbol}
    />;
  }

  return (
    <tr>
      <th scope="row">{symbol}</th>
      <td>{state.day}</td>
      <td className="text-right">{moneyFormat(state.open)}</td>
      <td className="text-right">{moneyFormat(state.close)}</td>
      <td className="text-right">
        {formatChange(state.change)}{/*&nbsp;({formatChange(state.percChange, '%')})*/}
      </td>
    </tr>
  );
}



const moneyFormat  = (num, dec = 2) => {
  return '$ ' + parseFloat(num, 10).toFixed(dec);
}
const formatChange = (num, suffix = '') => {
  num        = (Math.round(parseFloat(num, 10) * 100) / 100);
  let color  = '';
  let change = '';
  if (num > 0) {
    color  = 'success';
    change = '+ ';
  } else if (num < 0) {
    color  = 'danger';
    change = '- ';
  }

  return (
    <span className={color}>
      {change}{Math.abs(num).toFixed(2)}{suffix}
    </span>
  );
}

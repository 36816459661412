import React       from 'react';
import CssBaseline from '@material-ui/core/CssBaseline';
import Toolbar     from './Toolbar';
import Typography  from '@material-ui/core/Typography';
import Link        from '@material-ui/core/Link';

import { makeStyles } from '@material-ui/core/styles';
import SymbolDrawer   from './SymbolDrawer';
import Instructions   from './Instructions'


const useStyles = makeStyles((theme) => ({
  layout: {
    width: 'auto',
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
    [theme.breakpoints.up(600 + theme.spacing(2) * 2)]: {
      width: 600,
      marginLeft: 'auto',
      marginRight: 'auto',
    },
  },
}));

function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {'Copyright © '}
      <Link color="inherit" href="https://rhettl.com/">
        RhettL.com
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

function Layout ({children}) {
  const classes = useStyles();

  return (
    <>
      <CssBaseline />
      <Toolbar />
      <SymbolDrawer />
      <main className={classes.layout}>

        {children}

        <Instructions />
        <Copyright />
      </main>
    </>
  );
}

export default Layout;

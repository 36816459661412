import UiToolbar    from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import AppBar     from '@material-ui/core/AppBar';
import IconButton from '@material-ui/core/IconButton';
import EditIcon  from '@material-ui/icons/Edit'

import { makeStyles }     from '@material-ui/core/styles';
import { useRecoilState } from 'recoil';
import { drawerState }    from './SymbolDrawer';


const useStyles = makeStyles((theme) => ({
  appBar: {
    position: 'relative',
    flexGrow: 1,
  },
  title: {
    flexGrow: 1,
  },
}));



export default function Toolbar() {
  const classes = useStyles();
  const [ _, setDrawerOpen ] = useRecoilState(drawerState);

  return (
    <AppBar position="absolute" color="default" className={classes.appBar}>
      <UiToolbar>
        <Typography className={classes.title} variant="h6" color="inherit" noWrap>
          Stock Report
        </Typography>

        <IconButton aria-label="Change Symbols" color="inherit" onClick={() => setDrawerOpen(open => !open)}>
          <EditIcon />
        </IconButton>
      </UiToolbar>
    </AppBar>
  )
}

import { useRecoilValue } from 'recoil';
import Paper              from '@material-ui/core/Paper';
import { makeStyles }     from '@material-ui/core/styles';

import { symbolList }     from './state/symbols';
import SymbolRow          from './SymbolRow';


const useStyles = makeStyles((theme) => ({
  paper:   {
    marginTop:                                          theme.spacing(3),
    marginBottom:                                       theme.spacing(3),
    padding:                                            theme.spacing(2),
    [theme.breakpoints.up(600 + theme.spacing(3) * 2)]: {
      marginTop:    theme.spacing(6),
      marginBottom: theme.spacing(6),
      padding:      theme.spacing(3),
    },
  },
  table:   {
    width: '100%',

    '& td, th': {
      textAlign: 'center'
    },
    '& th:first-child': {
      textAlign: 'left'
    },
    '& .text-right': {
      textAlign: 'right'
    },
    '& .danger': {
      color: theme.palette.error.main,
    },
    '& .success': {
      color: theme.palette.success.main,
    },
    '& .muted': {
      color: theme.palette.grey[500],
    }
  },
  stepper: {
    padding: theme.spacing(3, 0, 5),
  },
  buttons: {
    display:        'flex',
    justifyContent: 'flex-end',
  },
  button:  {
    marginTop:  theme.spacing(3),
    marginLeft: theme.spacing(1),
  },
}));


export default function SymbolTable () {
  const classes     = useStyles();
  const symbols = useRecoilValue(symbolList);

  return (
    <Paper className={classes.paper}>
      {/*<Typography component="h1" variant="h4" align="center">*/}
      {/*  Checkout*/}
      {/*</Typography>*/}

      <table className={classes.table}>
        <thead>
          <tr>
            <th scope="col">Symbol</th>
            <th scope="col">Date</th>
            <th scope="col" className="text-right">Open</th>
            <th scope="col" className="text-right">Close</th>
            <th scope="col" className="text-right">Change</th>
          </tr>
        </thead>
        <tbody>

          {symbols.map(s => <SymbolRow key={s} symbol={s} />)}

        </tbody>
      </table>

    </Paper>
  );
}

import { atom, useRecoilState } from 'recoil';
import { makeStyles }           from '@material-ui/core/styles';
import { symbolList }           from './state/symbols';

import Drawer                  from '@material-ui/core/Drawer';
import List                    from '@material-ui/core/List';
import ListItem                from '@material-ui/core/ListItem';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemText            from '@material-ui/core/ListItemText';
import IconButton              from '@material-ui/core/IconButton';
import DeleteIcon              from '@material-ui/icons/Delete';
import ListSubheader           from '@material-ui/core/ListSubheader';
import AddCircleOutlineIcon    from '@material-ui/icons/AddCircleOutline';
import Divider                 from '@material-ui/core/Divider';
import FilledInput             from '@material-ui/core/FilledInput';
import FormControl             from '@material-ui/core/FormControl';
import InputAdornment          from '@material-ui/core/InputAdornment';
import InputLabel              from '@material-ui/core/InputLabel';
import { useState }            from 'react';

const useStyles = makeStyles(theme => ({
  list:    {
    width: 300,
    // display: 'flex',
    // flexWrap: 'wrap',
  },
  divider: {
    margin: theme.spacing(2, 0)
  },
  addForm: {
    margin: theme.spacing(1),
    // width:  '25ch'
  }
}));

export default function SymbolDrawer () {
  const classes                      = useStyles();
  const [ open, setOpen ]            = useRecoilState(drawerState);
  const [ symbols, setSymbolList ]   = useRecoilState(symbolList);
  const [ newSymbol, _setNewSymbol ] = useState('');

  const removeSymbol = (removeSymbol) => {
    setSymbolList(syms => syms.filter(s => s !== removeSymbol));
  };
  const addSymbol    = () => {
    const tempSymbol = newSymbol;
    setSymbolList(syms => {
      return [ ...syms, tempSymbol ]
        .filter((item, i, arr) => arr.indexOf(item) === i)
        .sort()
        ;
    });
    _setNewSymbol('');
  };
  const setNewSymbol = (s) => {
    _setNewSymbol(s.toUpperCase());
  }


  return (
    <Drawer anchor='right' open={open} onClose={() => setOpen(false)}>
      <div
        className={classes.list}
        role="presentation"
      >

        <List
          subheader={
            <ListSubheader component="div" id="nested-list-subheader">
              Symbols
            </ListSubheader>
          }
        >
          {symbols.map(s => (
            <ListItem key={s}>
              <ListItemText primary={s} />
              <ListItemSecondaryAction>
                <IconButton edge="end" aria-label="delete" onClick={() => removeSymbol(s)}>
                  <DeleteIcon />
                </IconButton>
              </ListItemSecondaryAction>
            </ListItem>
          ))}
        </List>

        <Divider className={classes.divider} />

        <div className={classes.addForm}>
          <FormControl fullWidth variant='filled'>
            <InputLabel htmlFor="add-symbol-input">Add New Symbol</InputLabel>
            <FilledInput
              id="add-symbol-input"
              type='text'
              value={newSymbol}
              onChange={e => {
                setNewSymbol(e.target.value)
              }}
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    aria-label="Add Symbol"
                    onClick={addSymbol}
                    onMouseDown={e => e.preventDefault()}
                    edge='end'
                  >
                    <AddCircleOutlineIcon />
                  </IconButton>
                </InputAdornment>
              }
            />
          </FormControl>
        </div>

      </div>
    </Drawer>
  );
}

export const drawerState = atom({
  key:     'drawerState',
  default: false
})

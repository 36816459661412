import { atom, atomFamily, selectorFamily, useSetRecoilState } from 'recoil';
import { getSymbol }                                           from './rapidapi'

export const symbolList = atom({
  key:     'symbols', // unique ID (with respect to other atoms/selectors)
  default: [ // default value (aka initial value)
    'AAPL',
    'GOOG'
  ],
  effects_UNSTABLE: [
    localStorageEffect('symbols'),
  ]
});

export const symbol = atomFamily({
  key:     'symbol',
  default: selectorFamily({
    key: 'symbol/default',
    get: (symbol) => {
      return ({ get }) => {
        get(forceUpdateFamily(symbol));
        return getSymbol(symbol);
      }
    }
  })
})

const forceUpdateFamily = atomFamily({
  key: "forceUpdate",
  default: false,
});
export function useSymbolReloader (symbol) {
  const update = useSetRecoilState(forceUpdateFamily(symbol));
  return () => {
    update(state => !state);
  }
}


function localStorageEffect (key) {
  return ({setSelf, onSet}) => {
    const savedValue = localStorage.getItem(key)
    if (savedValue != null) {
      setSelf(JSON.parse(savedValue));
    }

    onSet(newValue => {
      localStorage.setItem(key, JSON.stringify(newValue));
    });
  };
}

import makeStyles           from '@material-ui/core/styles/makeStyles';
import Typography           from '@material-ui/core/Typography';
import RefreshIcon          from '@material-ui/icons/Refresh';
import EditIcon             from '@material-ui/icons/Edit';
import DeleteIcon           from '@material-ui/icons/Delete';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';


const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    marginBottom: theme.spacing(3),

    '@media print': {
      display: 'none'
    },
  },
  headingSpacer: {
    marginTop: theme.spacing(3)
  },
  bodySpacer: {
    marginTop: theme.spacing(1)
  }
}));

export default function Instructions () {
  const classes = useStyles();

  return (
    <section className={classes.root}>
      <Typography variant='h6'>
        Instructions
      </Typography>

      <Typography variant='body1'>
        The program can only look up 5 stock symbols per minute. The program will space out your requests to prevent
        making too many, too fast. If one fails, wait a minute and click the refresh icon
        (<RefreshIcon fontSize='inherit' />). If all else fails, wait a minute and refresh the whole page.
      </Typography>

      <Typography variant='h6' className={classes.headingSpacer}>
        Add/Remove Symbols
      </Typography>

      <Typography variant='body1'>
        Click the edit icon (<EditIcon fontSize='inherit' />) in the top right to open the edit drawer. In the edit
        drawer you may remove stock symbols by clicking the delete icon (<DeleteIcon fontSize='inherit' />) or add stock
        symbols by typing in the new name into the "Add New Symbol" field and clicking the Add Symbol Button
        (<AddCircleOutlineIcon fontSize='inherit' />) to add to the list.
      </Typography>

      <Typography variant='body1' className={classes.bodySpacer}>
        No duplicate symbols allowed and symbols will be sorted alphabetically when a new one is added.
      </Typography>

      <Typography variant='subtitle1' className={classes.headingSpacer}>
        Note: These instructions will not appear when printed.
      </Typography>
    </section>
  );
}

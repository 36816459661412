import { format } from 'date-fns';

const endpoint = 'https://finnhub.io/api/v1';
const command  = 'quote';
const apiKey   = 'cm5l9b9r01qjc6l4i75gcm5l9b9r01qjc6l4i760';


export async function getSymbol (symbol) {
  let day;

  for (let count = 0; count < 3; count++) {
    try {
      day = await _getSymbol(symbol);         // try
    } catch (err) {
      if (err.type === 'TOOFAST') {           // if too fast
        await wait(1000);                 // wait 1 sec
      } else {
        throw err;
      }
    }
    if (day) {
      return day;
    }
  }

  let err  = new Error(`Failed to fetch symbol, ${symbol}: Too many attempts`);
  err.type = 'RETRYFAIL';
  throw err;
}

async function _getSymbol (symbol) {
  const { QuoteOutput: quote = {} } = await _quote(symbol);

  return {
    day:        quote.AsOfDate,
    open:       toFloat(quote.PricePreviousClose),
    close:      toFloat(quote.LastPrice),
    change:     quote.Change,
    percChange: quote.ChangePercent,
  }
}

async function _quote (symbol) {

  const url = `https://schwab.p.rapidapi.com/quote/get-summary?symbol=${symbol}`;
  const options = {
    method: 'GET',
    headers: {
      'X-RapidAPI-Key': 'WXEXeMaQrXmshysRzkDCM2M3QSBDp17XkjkjsnPWK4W0CtD7yo',
      'X-RapidAPI-Host': 'schwab.p.rapidapi.com'
    }
  };

  try {
    const response = await fetch(url, options);
    const result = await response.json();
    console.log(result);
    return result;
  } catch (error) {
    console.error(error);
  }
}

function wait (ms) {
  return new Promise(resolve => setTimeout(resolve, ms));
}

function toFloat (str = '') {
  return parseFloat(str.replace(/[,$]/g, ''));
}

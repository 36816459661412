import { RecoilRoot } from 'recoil';


export default function Context ({ children }) {
  return (
    <RecoilRoot>
        {children}
    </RecoilRoot>
  );
}

import React   from 'react';
import Layout  from './Layout';
import Context     from './Context';
import SymbolTable from './SymbolTable';



export default function Checkout () {
  return (
    <Context>
      <Layout>
        <SymbolTable />
      </Layout>
    </Context>
  );
}



